$enable-negative-margins: true;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600&family=Poppins:ital,wght@0,300;0,500;0,600;1,500&display=swap");

.App {
  font-family: sans-serif;
  text-align: center;          
}



.main-title {
  font-family: "gt-super", Georgia, "Times New Roman", serif;
  font-weight: 300;
  font-size: 42px;
  color: #344c38 !important;
  text-align: center;
}
.main-title-sm {
  font-family: "GT Super Text", Georgia, "Times New Roman", serif;
  font-weight: 400;
  font-size: 24px;
  color: #111827 !important;
  text-align: center;
  line-height: 32px;
  text-align: left;
}
.subtitle {
  font-family: "Inter", Arial, Helvetica, sans-serif;
  font-size: 21px;
  font-weight: 400;
  text-align: center;
  color: #6b7280;
  padding-left: 32px;
  padding-right: 32px;
  margin: auto;
}
.subtitle-sm {
  font-family: "Rand", Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #111827;
  padding-left: 0px;
  padding-right: 0px;
  line-height: 20px;
  margin: auto;
}


.button-group {
  display: flex;

  button {
    margin: 0 10px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.button {
  position: relative;
  display: flex;
  width: 100%;
  height: 56px;
  align-items: center;
  justify-content: center;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  color: #fff;
  border: 2px solid rgba(53, 156, 67, 1);
  background: rgba(53, 156, 67, 1);
  border-radius: 28px;
  padding: 13px 50px 13px 20px;
  transition-duration: 0.4s;
  cursor: pointer;

  i.fa {
    position: absolute;
    right: 20px;
    margin-top: -2px;
    font-size: 45px;
  }

  i.fa-spin {
    right: 10px;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.loading {
    opacity: 0.7;
    pointer-events: none;

    i.fa {
      font-size: 32px;
    }
  }

  &:hover {
    background: #fff;
    color: rgba(53, 156, 67, 1);
    box-shadow: 1px 1px 19px rgba(0, 0, 0, 0.3);
  }
}

.button-outline {
  color: rgba(53, 156, 67, 1);
  border: 2px solid rgba(53, 156, 67, 1);
  background: #fff;
}


.icon-button {
  position: relative;
  display: flex;  
  background: none;  
  border: 0;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.loading {
    opacity: 0.7;
    pointer-events: none;

    i.fa {
      font-size: 32px;
    }
  }

}

.prev-button {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 56px;
  height: 56px;
  border-radius: 100%;
  font-size: 45px;
  padding-left: 2px;
  padding-top: 0px;
  color: rgba(53, 156, 67, 1);
  border: 2px solid rgba(53, 156, 67, 1);
  background: #fff;
  transition-duration: 0.4s;

  &:disabled {
    opacity: 0.2;
    pointer-events: none;
  }

  &.loading {
    opacity: 0.7;
    pointer-events: none;

    i.fa {
      margin-left: 3px;
      font-size: 32px;
    }
  }

  &:hover {
    color: #fff;
    background: rgba(53, 156, 67, 1);
  }
}

/* Steps Component - START */

.steps-button-container {  
  background-color: #FFF;
  width: 100%;
  display: flex;
  justify-content: left;  
  gap: 4px;
  padding: 10px;

  .step-menu-button {
    margin-top: auto;
    margin-bottom: auto;
  }

  .step-number {
    text-align: left;
    font-size: 16px;
    margin-bottom: -3px;
    font-family: "Inter";
    font-style: italic;      
  }

  .step-label {
    text-align: left;
    font-size: 16px;
    margin-bottom: -3px;
    font-family: "Inter";
    font-style: italic;
    font-weight: 700;
    color: #344c38;
  }
}    


.steps-sidebar-mask {
  position: fixed;  
  top: 0;
  background-color:rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 9998;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}

.steps-sidebar-container {      
  position: absolute;
  animation: fade-in 0.3s;
  background-color: #FFF;  
  top: 0;  
  left: 0;    
  height: 100%;
  z-index: 9999;  

  .customer-summary-container {
    margin: 20px;
  }

  .close-button {
    display: flex;
    height: 48px;
    width: 48px;        
    padding-top: 4px;
    margin-right: 0;    
    margin-left: auto;
  }
}

.steps-menu {  
  background-color: #FFF; 
  padding: 10px 10px;   
  
  .step-item {
    display: flex;
    align-items: center;
    border: 2px solid #e4e7eb;  
    margin-left: 2px;
    margin-right: 2px;
    margin-bottom: 8px;
    height: 50px;
    border: 0;
    padding-right: 16px;  
  
    .step-status {
      color: #e4e7eb;
      margin-right: 10px;
      width: 39px;
      height: 39px;
      display: flex;
      justify-content: center;
      align-items: center;    
  
      .step-vertical-line, .step-vertical-line-green {
        position: absolute;
        border-left: 3px solid #e4e7eb;            
        height: 36px;
        margin-left: 15px;      
        top: 26px;
      }
  
      .step-vertical-line-green {
        border-left: 3px solid #344c38;            
      }
  
      i.fa {
        font-size: 32px;      
      }
    }
  
    .step-data {
      text-align: left;
  
      .step-active {
        font-weight: 700;
        color: #344c38;
      }
  
      .step-complete {
        text-decoration: line-through;
      }
  
      .step-number {
        font-size: 16px;
        margin-bottom: -3px;
        font-family: "Inter";
        font-style: italic;
      }
  
      .step-title {
        font-family: "Inter", Helvetica, Arial, sans-serif;
        font-size: 16px;
        font-weight: 400;
        font-style: italic;
      }    
    }
  
    &.done {
      color: #344c38;
      border-color: #344c38;
      cursor: pointer;
  
      .step-status {
        color: #344c38;
      }
  
      .step-status {
        color: #344c38;
      }
  
    }
  
    &.active {
      color: #344c38;
      border-color: #344c38;
      
  
      .step-status {
        color: #344c38;
        border-radius: 100%;
      }
    }
  
    &.disabled {
      pointer-events: none;
    }
  }
}

/* Steps Component - END */

.panel {
  padding: 25px;
  border-radius: 16px 16px 16px 16px;
  overflow: hidden;
  border: 2px solid #d8d8d8;
  width: 100%;
  max-width: 584px;
}

.new-steps-wrapper {
  justify-content: center;
}

.new-step-item {
  display: flex;
  align-items: center;  
  border: 2px solid #e4e7eb;
  width: 300px;
  margin-left: 2px;
  margin-right: 2px;
  margin-bottom: 8px;
  height: 50px;
  border-radius: 50px;
  padding-right: 16px;
  box-shadow: 1px 1px 19px rgba(0, 0, 0, 0.1);

  .new-step-status {
    color: #e4e7eb;
    margin-right: 20px;
    width: 39px;
    height: 39px;
    display: flex;
    justify-content: center;
    align-items: center;

    i.fa {
      font-size: 50px;
    }
  }

  .new-step-data {
    text-align: left;

    .new-step-number {
      font-size: 12px;
      margin-bottom: -3px;
      font-family: Georgia, "Times New Roman", Times, serif;
    }

    .new-step-title {
      font-family: "Inter", Helvetica, Arial, sans-serif;
      font-size: 16px;
      font-weight: 900;
    }
  }

  &.done {
    color: #359c43;
    border-color: #359c43;
    cursor: pointer;

    .new-step-status {
      color: #359c43;
    }

    .new-step-status {
      color: #359c43;
    }

    &:hover {
      background: rgba(53, 156, 67, 0.1);
    }
  }

  &.active {
    color: #333;
    border-color: #333;

    .new-step-status {
      color: #333;
      border-radius: 100%;
    }
  }

  &.disabled {
    pointer-events: none;
  }
}

.express-accordion {
  .accordion-header {
    .accordion-button {
      padding: 6px 20px 6px 6px;
      
      &:not(.collapsed) {
        background-color: #cbf3d0;
      }
    }
  }
}

.express-step-item {
  display: flex;
  align-items: center;  
  width: 290px;
  height: 50px;  
  padding-right: 16px;
  
  .new-step-status {
    color: #e4e7eb;
    margin-right: 20px;
    width: 39px;
    height: 39px;
    display: flex;
    justify-content: center;
    align-items: center;

    i.fa {
      font-size: 50px;
    }
  }

  .express-step-data {
    text-align: left;

    .express-step-number {
      font-size: 12px;      
      font-family: Georgia, "Times New Roman", Times, serif;
    }

    .express-step-title {
      font-family: "Inter", Helvetica, Arial, sans-serif;
      font-size: 16px;
      font-weight: 900;
    }
  }

  &.done {
    color: #359c43;
    border-color: #359c43;
    cursor: pointer;

    .express-step-status {
      color: #359c43;
    }

    .express-step-status {
      color: #359c43;
    }

    &:hover {
      background: rgba(53, 156, 67, 0.1);
    }
  }

  &.active {
    color: #333;
    border-color: #333;

    .express-step-status {
      color: #333;
      border-radius: 100%;
    }
  }

  &.disabled {
    pointer-events: none;
  }
}

.express-signup-wait {
  font-weight: 600;
  width: 100%;  
  margin: auto;
  font-family: "Inter", Arial, Helvetica, sans-serif;
  text-align: center;
  font-size: 30px;
  height: 280px;
}

.contact-supporter {
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  margin: 12px auto;
  padding-left: 10px;
  padding-right: 10px;
  color: #666;
  font-family: "Inter", Arial, Helvetica, sans-serif;
}

.disclaimer {
  margin-top: 20px;
  margin-bottom: 32px;
  font-size: 14px;
  text-align: left;
  color: #666;
  font-family: "Inter", Arial, Helvetica, sans-serif;

  p {
    text-align: left;
  }
}

.loading-msg {
  text-align: center;

  i.fa {
    margin-top: 6px;
  }
}

.json-pre {
  padding: 10px;
  background-color: #f5f5f5;
  color: #333;
  font-family: monospace;
  font-size: 14px;
  text-align: left;

  &:before {
    content: "DEBUG";
    display: block;
    font-weight: bold;
    margin-bottom: 10px;
  }

  code {
    display: block;
    padding: 0;
    overflow: auto;
    word-wrap: normal;
    font-family: monospace;
    font-size: 14px;
  }

  .string {
    color: #e75f00;
  }

  .number {
    color: #09885a;
  }

  .boolean {
    color: #0086b3;
  }

  .null {
    color: #666;
  }

  .key {
    color: #333;
    font-weight: bold;
  }

  .bracket {
    color: #333;
  }
}

iframe {
  overflow: hidden;
}

.maintenance-mode {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;

  .maintenance-wrapper {
    max-width: 400px;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  h3 {
    font-size: 30px;
    font-weight: 700;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  }

  p {
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 10px;
    color: #4b5563;
  }
}

.payment-wrapper {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
  text-align: left;
  padding-bottom: 30px;

  h3 {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  iframe {
    width: 100%;
    height: 35px;
    margin-bottom: 10px;
  }

  .expiration-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    select {
      width: 100px;
      height: 35px;
      padding: 5px 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      margin: 5px 5px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .error-message {
    color: #bb290c;
    font-size: 16px;
    margin: 10px 0;
  }
}

.container {
  background-color: #fff;
  margin: auto;
  margin-top: 32px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  max-width: 700px;
  box-shadow: 1px 1px 19px rgba(0, 0, 0, 0.1);

  .step-3 &,
  .step-5 &,
  .step-6 &,
  .step-7 & {
    padding: 32px;
  }
}

.contact-btns {
  display: flex;
  justify-content: center;
  align-items: center;

  .step-3 &,
  .step-7 & {
    justify-content: space-between;
  }
}

.contact-btns-right {
  width: 100%;
}

.place-order-button-below-text {
  margin-top: 16px;
}

.place-order-error-text {
  color: red;
  text-align: center;
  margin-bottom: 32px;
}

.contact-btns-left {
  width: 56px;
}

.unlimited {
  font-style: italic;
}

/* Start - Step 1 */

.container-step-1 {
  background: #FFF;
  padding-left: 16px;
  padding-right: 16px;

  .form-container {
    max-width: 700px;
    margin: auto;
    border: 1px solid rgba(0, 0, 0, 0.3);
    padding: 32px;
    border-radius: 8px;
    margin-top: 32px;
    box-shadow: 1px 1px 19px rgba(0, 0, 0, 0.1);

    input.pac-target-input {
      width: 100%;
      height: 38.18px;
      margin-bottom: 32px;
      font-family: "Inter", Arial, Helvetica, sans-serif;
      outline: none;
      text-align: center;
    }
  }

  .address-title {
    font-weight: 600;
    width: 100%;
    text-align: left;
    margin-bottom: 8px;
    font-family: "Inter", Arial, Helvetica, sans-serif;
  }

  .address-title-discount {
    font-weight: 600;
    text-align: left;
    margin-bottom: 8px;
    font-family: "Inter", Arial, Helvetica, sans-serif;
  }

  .description {
    font-family: "Inter", Arial, Helvetica, sans-serif;
    margin-bottom: 32px;
    color: #666;
  }

  .discount-input {
    width: 100%;
    height: 45px;
    margin-bottom: 32px;
    border-radius: 4px;
    font-family: "Inter", Arial, Helvetica, sans-serif;
    outline: none;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.3);
  }
}

/* End Step 1 */

/* Start Step 2 */

.plan {
  background-color: #FFF;
  font-size: 14px;
  max-width: 884px;
  margin: 0 auto;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #333;
    padding-bottom: 10px;
    line-height: 1em;
  }

  h2 {
    font-family: "gt-super", Georgia, "Times New Roman", serif;
    font-weight: 700;

    &.price {
      font-size: 30px;

      span {
        font-size: 14px;
      }
    }
  }

  button {
    padding: 1px 6px !important;
  }

  .title {
    max-width: 584px;
    margin: 0 auto 30px auto;
    text-align: center;
  }

  .desktop {
    display: block !important;
  }

  .desktop-flex {
    display: flex !important;
  }

  .mobile,
  .mobile-flex {
    display: none !important;
  }

  .promo-price-wrapper {
    display: flex;
    width: 100%;
    align-items: center;

    span {
      width: 100%;
    }

    .price-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      justify-content: center;

      .promo-prices {
        display: flex;
        align-items: center;
        justify-content: center;

        .old-price {
          position: relative;
          font-size: 18px;
          margin-right: 6px;
          padding-top: 10px;

          &:after {
            content: " ";
            position: absolute;
            width: 100%;
            left: 0;
            top: 22px;
            border-bottom: 2px solid #000;
            transform: rotate(20deg);
          }

          &.premium {
            &:after {
              border-bottom: 2px solid #fff;
            }
          }
        }
      }

      .online-promotion {
        font-size: 12px;
        text-transform: uppercase;
        font-family: "Inter", Arial, Helvetica, sans-serif;
        color: #359d42;

        &.premium {
          color: #e1ff31;
        }
      }
    }

    .treatment-description {
      font-size: 14px;
      width: 100%;
      padding-left: 10px;
    }
  }

  .standard-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;

    .price-value {
      margin-right: 5px;
      padding-bottom: 4px;
    }
  }

  .plan-row {
    display: flex;
    justify-content: center;

    &.plan-top {
      justify-content: flex-end;
    }
  }

  .plan-col {
    flex-basis: 33%;
    position: relative;
  }

  .pro,
  .pro-plus {
    max-width: 284px;
    position: relative;
    padding: 24px 16px 16px;
    border-radius: 28px;
    text-align: left;

    h2 {
      font-size: 35px;
    }

    .number {
      font-size: 12px;
      display: inline-block;
      margin-left: 7px;
    }

    .price {
      margin-bottom: 15px;
      text-align: center;
      line-height: 23px;
    }

    .button {
      font-size: 20px;
      font-weight: bold;
      transition: 0.4s;
      width: 100%;
      height: 56px;
      background-color: transparent;
      border-radius: 28px;
      border: 2px solid;
      cursor: pointer;
    }
  }

  .pro {
    background: #ebf7e8;

    .button {
      color: #359c43;
      border-color: #359c43;

      &:hover {
        color: #ebf7e8;
        background-color: #359c43;
      }
    }
  }

  .pro-plus {
    background: #344d38;
    color: #f1efeb;
    height: 100%;

    h2,
    span {
      color: #f1efeb;
    }

    .button {
      color: #e1ff32;
      border-color: #e1ff32;

      &:hover {
        color: #344d38;
        background-color: #e1ff32;
      }
    }
  }

  .pro-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .popular {
    position: absolute;
    font-size: 12px;
    text-transform: uppercase;
    color: #0d2110;
    padding: 8px 16px;
    line-height: 12px;
    margin: 0;
    background: #e1ff32;
    font-weight: bold;
    border-radius: 4px;
    top: -10px;
    right: -10px;
  }

  .selected {
    position: absolute;
    font-size: 12px;
    text-transform: uppercase;
    color: #FFF;
    padding: 8px 16px;
    line-height: 12px;
    margin: 0;
    background: #359c43;
    font-weight: bold;
    border-radius: 4px;
    top: -10px;
    left: -10px;
  }

  .stars {
    margin: 10px 0;
  }

  p {
    margin-bottom: 18px;
    font-size: 13px;
    text-align: center;
  }

  .includes,
  .names {
    div {
      min-height: 64px;
      display: flex;
    }
  }

  .names {
    background: #f2f1ee;
    padding-right: 24px;

    div {
      padding: 10px;
      justify-content: flex-start;
      align-items: center;
      font-size: 15px;
      font-weight: bold;
      font-family: "Inter", Arial, Helvetica, sans-serif;
    }
  }

  .includes {
    margin-left: 24px;

    div {
      justify-content: center;
      align-items: center;
      border-bottom: 2px solid #f2f1ee;

      .button-plan-plus {
        border-color: #e1ff32;
        background: #e1ff32;
        color: #344d38;

        &:hover {
          background: #fff;
        }
      }

      &:last-child {
        border-bottom: none;
        margin-top: 10px;
      }
    }
  }

  .font-italic {
    font-style: italic;
    font-family: "Inter", Arial, Helvetica, sans-serif;
  }

  .et-pb-icon {
    font-size: 30px;
    font-family: FontAwesome !important;
    font-weight: 900 !important;

    &.icon-close {
      font-family: ETmodules !important;
      font-weight: 700 !important;
      color: #b6b6b6;
      font-size: 40px;
      line-height: 1;
    }

    &.icon-star {
      color: #f5cf32;
      font-size: 16px;
    }

    &.icon-green {
      color: #359c43;
    }
  }
}

.price-value {
  font-size: 30px !important;
}

/* End Step 2 */

/* Start Step 3 */

.step-3 {
  background-color: #FFF;

  .address-title {
    text-align: left;
    font-family: "Inter", Arial, Helvetica, sans-serif;
    color: #666;
  }

  .address-content {
    text-align: left;
    font-family: "Inter", Arial, Helvetica, sans-serif;
    font-weight: 700;
    color: #666;
    margin-bottom: 16px;
  }

  .info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 16px;
  }

  .info-tab {
    display: flex;
    width: 50%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .info-tab-title {
    font-family: "Inter", Arial, Helvetica, sans-serif;
    color: #666;
    text-align: left;
  }

  .info-tab-content {
    font-family: "Inter", Arial, Helvetica, sans-serif;
    font-weight: 700;
    color: #666;
    text-align: left;
  }

  .contact {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .contact-title {
    text-align: left;
    font-family: "Inter", Arial, Helvetica, sans-serif;
    font-size: 26px;
  }

  .contact-title-note {
    margin-bottom: 16px;
    text-align: left;
    font-size: 12px;
  }

  .contact-form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .contact-input {
    width: 49%;
    margin-bottom: 16px;
    position: relative;
  }

  .contact-input-error {
    color: red;
    width: 100%;
    text-align: left;
  }

  .contact-input-label {
    text-align: left;
    font-family: "Inter", Arial, Helvetica, sans-serif;
    color: #666;
    margin-bottom: 8px;
  }

  .contact-input-input-wrapper {
    text-align: left;
  }

  .contact-input-input {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.3);
    height: 45px;
    border-radius: 4px;
    font-family: "Inter", Arial, Helvetica, sans-serif;
    outline: none;
    padding-left: 16px;
  }
  .contact-input-input:focus {
    box-shadow: 1px 1px 19px rgba(0, 0, 0, 0.1);
  }

  .contact-back-btn {
    width: 56px;
    padding-left: 0px !important;
    padding-right: 0px !important;
    background-color: white !important;
    color: rgb(53, 156, 67) !important;
  }

  .contact-back-btn:hover {
    background-color: rgb(53, 156, 67) !important;
    color: white !important;
  }

  .contact-btns-right {
    width: calc(100% - 60px);
  }

  .contact-input-required {
    color: red;
  }

  .contact-terms {
    margin-bottom: 32px;
    font-size: 14px;
    text-align: left;
    color: #666;
    font-family: "Inter", Arial, Helvetica, sans-serif;
  }
}


/* End Step 3 */

/* Start Step 4 */

.step-4 {
  background-color: #FFF;

  .summary {
    padding: 32px;
  }
}

/* End Step 4 */

/* Start Step 5 */
.step-5 {
  background-color: #FFF;
}

/* End Step 5 */

/* Start Step 6 */

.step-6 {
  background-color: #FFF;

  .title {
    text-align: center;
    font-family: "Inter", Arial, Helvetica, sans-serif;
    font-weight: 700;
    color: #666;
    font-size: 21px;
  }

  .subtitle {
    text-align: center;
    font-family: "Inter", Arial, Helvetica, sans-serif;
    color: #666;
    font-size: 21px;
  }

  .title-section {
    padding-bottom: 32px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
    margin-bottom: 16px;
  }

  .info {
    padding-bottom: 16px;
    border-bottom: none !important;
  }

  .contact-btns {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 20px;
    gap: 5px;
  }
}

/* End Step 6 */

/* Start Step 7 */

.step-7 {
  .youtube {
    margin-top: 16px;
    margin-bottom: 32px;

    iframe {
      max-height: 350px;
      width: 100%;
    }
  }

  .youtube-title {
    font-family: "Inter", Arial, Helvetica, sans-serif;
    color: #666;
    margin-bottom: 16px;

    a {
      text-decoration: none;
    }
  }
}

/* End Step 7 */

/* DaySchedule Component */

.date-slot {
  margin-bottom: 16px;
}

.date-slot-title {
  font-family: "Inter", Arial, Helvetica, sans-serif;
  text-align: left;
  margin-bottom: 8px;
  font-weight: 600;
}

.date-slot-pickers {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.date-slot-pickers button:disabled {
  opacity: 0.3 !important;
}

.date-slot-btn {
  font-size: 14px !important;
  padding: 13px 20px 13px 20px !important;
  height: 39px !important;

  svg {
    left: 20px !important;
    position: absolute;
    right: 20px;
  }
}

.date-slot-btn-am {
  width: 49% !important;
  background: white !important;
  color: #e0834f !important;
  border: 1px solid #e0834f !important;

  svg {
    fill: #e0834f;
  }

  &.selected-date-slot {
    background: #e0834f !important;
    color: white !important;
    border: 1px solid #e0834f !important;

    svg {
      fill: white !important;
    }
  }

  &:hover {
    background: #e0834f !important;
    color: white !important;
    border: 1px solid #e0834f !important;

    svg {
      fill: white !important;
    }
  }
}

.date-slot-btn-pm {
  width: 49% !important;
  background: white !important;
  color: #233876 !important;
  border: 1px solid #233876 !important;

  svg {
    fill: #233876;
  }

  &.selected-date-slot {
    background: #233876 !important;
    color: white !important;
    border: 1px solid #233876 !important;

    svg {
      fill: white !important;
    }
  }

  &:hover {
    background: #233876 !important;
    color: white !important;
    border: 1px solid #233876 !important;

    svg {
      fill: white !important;
    }
  }
}

/* Week Schedule Component */

.week-time {
  position: relative;
  border-top: 2px solid rgba(0, 0, 0, 0.2);
}

.week-time-label {
  font-family: "Inter", Arial, Helvetica, sans-serif;
  font-weight: 600;
  width: 100%;
  text-align: center;
  margin-bottom: 16px;
}

.week-time-label-text {
  background: white;
  position: relative;
  top: -15px;
  color: #666;
}

.week-container {
  margin-bottom: 64px;
}

/* Input Component */

.label {
  text-align: left;
  font-family: "Inter", Arial, Helvetica, sans-serif;
  color: #111827;;
  margin-bottom: 8px;
}
.label-sm {
  text-align: left;
  font-family: "Inter", Arial, Helvetica, sans-serif;
  color: #111827;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 600;
}
.inputWrapper {
  text-align: left;
}

.input {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.3);
  height: 45px;
  border-radius: 4px;
  font-family: "Inter", Arial, Helvetica, sans-serif;
  outline: none;
  padding-left: 16px;

  &:focus {
    box-shadow: 1px 1px 19px rgba(0, 0, 0, 0.1);
  }
}

/* CustomerSummaryInfo Component */

.customer-summary {
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);  

  .title {
    text-align: left;    
    color: #6B7280;    
    font-family: "Inter", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    font-style: italic;
  }

  .content {
    text-align: left;
    font-family: "Inter", Arial, Helvetica, sans-serif;
    font-weight: 700;
    color: #212429;
    margin-bottom: 16px;
  }
}

/* CustomerSummaryInfo Component - Legacy */

.customerSummaryInfo {
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 16px;

  .title {
    text-align: left;
    font-family: "Inter", Arial, Helvetica, sans-serif;
    color: #666;
    font-weight: 700;
  }

  .content {
    text-align: left;
    font-family: "Inter", Arial, Helvetica, sans-serif;
    font-weight: 400;
    color: #666;
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 1855px) {
  .steps-wrapper {
    justify-content: space-between;

    .new-step-item {
      width: calc(100% / 3 - 8px);
      margin-right: 0px;
    }
  }
  
}

@media only screen and (max-width: 1230px) {
  .steps-wrapper {
    justify-content: flex-start;

    .step-item {
      width: calc(100% / 4 - 10px);
    }
  }
}

@media (max-width: 900px) {
  .plan {
    .plan-row {
      &.plan-top {
        margin-bottom: 60px;

        .plan-col {
          flex-basis: 50%;
        }
      }

      &.plan-bottom {
        .plan-col {
          flex-basis: 25%;

          &:first-child {
            flex-basis: 50%;
          }
        }
      }
    }

    .pro,
    .pro-plus {
      margin: 0 auto;
    }

    .desktop,
    .desktop-flex {
      display: none !important;
    }

    .mobile {
      display: block !important;
    }

    .mobile-flex {
      display: flex !important;
    }

    .popular,
    .title.mobile {
      position: absolute;
      top: -28px;
      left: 50%;
      transform: translate(-50%, 0);
    }

    .title.mobile {
      font-weight: 900;
      font-size: 24px;
      color: #0d2110;
    }

    .names {
      div {
        font-size: 16px;
      }
    }

    .stats {
      background: #f2f1ed;
      margin-bottom: 64px;
      border-radius: 16px;
      flex-wrap: wrap;
      justify-content: space-between;
      display: flex;
    }

    .image {
      width: 100%;
      height: 240px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
      }
    }

    .humidity span:last-of-type,
    .wind span:last-of-type,
    .feel span:last-of-type {
      font-weight: bold;
    }
  }
}

@media only screen and (max-width: 876px) {
  .steps-wrapper {
    .step-item {
      width: calc(100% / 3 - 10px);
    }
  }
}

@media only screen and (max-width: 867px) {
  .steps-wrapper {
    justify-content: space-between;

    .new-step-item {
      width: 49%;
      margin-right: 0px;
    }
  }
}

@media only screen and (max-width: 690px) {
  .contact-form-line {
    flex-direction: column;
  }
  .contact-input {
    width: 100%;
  }

  .contact-input-couple {
    width: 100%;
    flex-direction: column;
  }

  .youtube iframe {
    max-height: 60vw;
  }
}

@media only screen and (max-width: 664px) {
  .steps-wrapper {
    justify-content: space-between;

    .step-item {
      width: calc(100% / 2 - 10px);
      padding: 8px;
      border-radius: 8px;
      border: 2px solid #e4e7eb;
    }

    .step-item.active {
      border: 2px solid #333;
    }

    .step-item.done {
      border: 2px solid #359c43;
    }

    .new-step-item {
      width: 100%;
      margin-right: 0px;
    }
  }
}

@media only screen and (max-width: 630px) {
  .about-row {
    flex-direction: column;
    gap: unset !important;

    span {
      margin-bottom: 4px;
    }
  }
}

@media (max-width: 625px) {
  .plan {
    .pro,
    .pro-plus {
      max-width: 95%;
      padding: 16px;

      h2 {
        &.price {
          font-size: 24px;
          font-weight: 400;
        }
      }

      .button {
        font-size: 16px;
      }
    }

    .popular {
      min-width: 120px;
      padding: 8px 12px;
    }

    .selected {     
      min-width: 120px; 
      padding: 8px 12px;
    }

    .names {
      div {
        font-size: 14px;
      }
    }

    .font-italic {
      font-size: 10px;
    }

    .promo-price-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;

      .price-wrapper {
        .promo-prices {
          .old-price {
            font-size: 18px;
            margin-right: 6px;
            padding-top: 10px;

            &:after {
              content: " ";
              position: absolute;
              width: 100%;
              left: 0;
              top: 22px;
              border-bottom: 2px solid #000;
              transform: rotate(20deg);
            }

            &.premium {
              &:after {
                border-bottom: 2px solid #fff;
              }
            }
          }
        }

        .online-promotion {
          font-size: 12px;

          &.premium {
            color: #e1ff31;
          }
        }
      }

      .treatment-description {
        font-size: 14px;
        width: 100%;
      }
    }

    .standard-wrapper {
      display: flex;
      flex-direction: column;

      .price-value {
        margin-right: 5px;
        padding-bottom: 4px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .contact-input {
    width: 100%;
  }
}

@media only screen and (max-width: 532px) {
  .date-slot-pickers {
    flex-direction: column;
  }
  .date-slot-btn-am {
    width: 100% !important;
    margin-bottom: 8px;
  }

  .date-slot-btn-pm {
    width: 100% !important;
  }
}

@media only screen and (max-width: 478px) {
  .steps-wrapper {
    padding-left: 16px;
    padding-right: 16px;

    .step-item {
      width: 100%;
    }
  }
}

@media (max-width: 400px) {
  .plan {
    .button {
      font-size: 16px !important;
    }
  }
}

@media (min-width: 768px) {
  .fs-lg {
    font-size: 16px; /* Adjust the size as needed */
    padding: 10px;
  }
}

@media (max-width: 767px) {
  .fs-sm {
    font-size: 16px; /* Adjust the size as needed */
  }
}

@media (max-width: 767px) {
  .title-sm {
    font-size: 32px; /* Adjust the size as needed */
  }
}

@media (min-width: 767px) {
  .title-lg {
    font-size: 16px; /* Adjust the size as needed */
  }
}

/* New Checkout */
.main-container {  
  margin: auto;

  .title-container {
    margin-top: 20px;
  }

  .body-container {
    height: 100%;
  }

  .step-menu-and-summary-container {
    height: 100%;
    background: #FFF;
    padding: 20px;
    width: fit-content;
  }

  .step-menu-and-summary-container, .current-component-container {        
    vertical-align: top;
    margin: 8px;
    display: inline-block;          
    border-radius: 8px;
  }
}

.mobile-main-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 10px;  

  .current-component-container {
    flex-grow: 1;
  }
}

.main-title-lg {
  font-family: "gt-super", Georgia, "Times New Roman", serif;
  font-weight: 300;
  font-size: 42px;
  color: #FFF !important;
  text-align: left;
  text-shadow: 0px 2px 6px #000
}

.subtitle-lg {
  font-family: "Inter", Arial, Helvetica, sans-serif;
  font-size: 21px;
  font-weight: 400;
  text-align: left;
  color: #FFF;
  padding-left: 32px;
  padding-right: 32px;
  margin: auto;
}

.contact-component {
  background: #F5F7ED;
}

.contact-component-mobile {
  background: #FFF;
}

.contact-component, .contact-component-mobile {  
  padding-left: 16px;
  padding-right: 16px;

  .form-container {    
    margin: auto;
    border: 1px solid rgba(0, 0, 0, 0.3);
    padding: 32px;
    border-radius: 8px;
    margin-top: 32px;
    box-shadow: 1px 1px 19px rgba(0, 0, 0, 0.1);

    input.pac-target-input {
      width: 100%;
      height: 38.18px;
      margin-bottom: 32px;
      font-family: "Inter", Arial, Helvetica, sans-serif;
      outline: none;
    }
  }

  .address-title {
    font-weight: 600;
    width: 100%;
    text-align: left;
    margin-bottom: 8px;
    font-family: "Inter", Arial, Helvetica, sans-serif;
  }

  .address-title-discount {
    font-weight: 600;
    text-align: left;
    margin-bottom: 8px;
    font-family: "Inter", Arial, Helvetica, sans-serif;
  }
  .description {
    text-align: left;
    font-family: "Inter", Arial, Helvetica, sans-serif;
    margin-bottom: 32px;
    color: #000;
  }
  .description-sm {
    font-family: "Rand", Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: 400;
    font-style: italic;
    line-height: 16px;
    color: #212429;
    text-align: left;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .unlimited {
    font-style: italic;
  }

  .discount-input {
    width: 100%;
    height: 45px;
    margin-bottom: 32px;
    border-radius: 4px;
    font-family: "Inter", Arial, Helvetica, sans-serif;
    outline: none;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.3);
  }

  .contact-terms-sm {
    margin-bottom: 32px;
    font-size: 12px;
    text-align: left;
    color: #979CA7;
    font-family: "Rand", Arial, Helvetica, sans-serif;
    font-style: italic;
    line-height: 16px;
  }
  
  .contact-terms {
    font-size: 12px;
    text-align: left;
    color: #111111;
    font-family: "Inter", Arial, Helvetica, sans-serif;
    line-height: 16px;
  }
  
  .contact-terms a {
    text-decoration: none;
  }  
}

.plan-component {
  min-width: 1000px;
}

.plan-component-mobile {
  padding-top: 10px;
}

.plan-component,
.plan-component-mobile {
  gap: 16px;
}

.view-plans-component,
.plan-component,
.plan-component-mobile {
  font-size: 14px;    
  margin: 0 auto;
    
  .bg-white {
    .button {
      color: #009041;
      background-color: transparent;
      font-size: 16px;
      font-family: "Rand", Arial, Helvetica, sans-serif;;
      font-weight: 700;
      transition: 0.4s;
      width: 100%;
      height: 48px;
      border-radius: 28px;
      cursor: pointer;
    }
    .pro-row,
    .plan-row {
      .plan-item {
        font-family: "Inter", Arial, Helvetica, sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #333333;

        p {
          text-align: left;
          color: #6B7280;
          font-size: 12px;
          font-style: italic;
          line-height: 16px;
          font-weight: 400;
        }
      }
    }
    .separate {
      border: 1px solid #EBF7E8;
      border-top: none;
    }
  }

  .bg-plan {
    background-color: #E7F5E5;
    .button {
      color: white;
      background-color: #309C42;
      font-size: 16px;
      font-family: "Rand", Arial, Helvetica, sans-serif;;
      font-weight: 700;
      transition: 0.4s;
      width: 100%;
      height: 48px;
      border-radius: 28px;
      cursor: pointer;
    }
    .pro-row {
      .plan-item {
        font-family: "Inter", Arial, Helvetica, sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #333333;

        p {
          text-align: left;
          color: #6B7280;
          font-size: 11px;
          font-style: italic;
          line-height: 13.31px;
        }
      }
    }
  }

  h2 {
    font-family: "GT Super Text", Georgia, "Times New Roman", serif;
    font-weight: 700;

    &.price {
      font-size: 30px;

      span {
        font-size: 14px;
      }
    }
  }

  button {
    padding: 1px 6px !important;
  }

  .title {
    max-width: 584px;
    margin: 0 auto 30px auto;
    text-align: center;
  }

  .desktop {
    display: block !important;
  }

  .desktop-flex {
    display: flex !important;
  }

  .mobile,
  .mobile-flex {
    display: none !important;
  }

  .plan-row {
    display: flex;
    justify-content: center;

    &.plan-top {
      justify-content: flex-end;
    }
  }

  .plan-col {
    flex-basis: 33%;
    position: relative;
  }

  .pre {
    max-width: 100%;
    position: relative;
    border-radius: 8px;
    text-align: left;

    h2 {
      font-size: 40px;
    }

    .number {
      font-size: 12px;
      display: inline-block;
      margin-left: 7px;
    }

    .price {
      text-align: center;
      line-height: 48px;
      color: #344C38;
      font-weight: 300;
    }

    .price-desc {
      font-family: "Rand Mono", Arial, Helvetica, sans-serif;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      color: #344C38;
    }

    .pre-plus-bg {
      background-color: #0D210F;
    }
  }
  .pro,
  .pro-plus {
    max-width: 100%;
    position: relative;
    border-radius: 8px;
    text-align: left;

    h2 {
      font-size: 40px;
    }

    .number {
      font-size: 12px;
      display: inline-block;
      margin-left: 7px;
    }

    .price {
      text-align: center;
      line-height: 48px;
      color: #344C38;
      font-weight: 300;
    }

    

    .pro-plus-bg {
      background-color: #344C38;
    }
  }

  .bg-white {
    background: #ebf7e8;

    .button {
      color: #009041;
      border-color: #009041;

      &:hover {
        color: #ebf7e8;
        background-color: #009041;
      }
    }
  }

  .bg-plan {
    background: #ebf7e8;

    .button {
      color: white;

      &:hover {
        color: #309C42;
        background-color: white;
      }
    }
  }

  .pro-plus {
    background: #344d38;
    color: #f1efeb;
    height: 100%;

    h2,
    span {
      color: #f1efeb;
    }

    .button {
      color: #e1ff32;
      border-color: #e1ff32;

      &:hover {
        color: #344d38;
        background-color: #e1ff32;
      }
    }
  }

  .pro-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .popular-badge {
      right:0;
      top: 0;
      margin-right: 8px;
      margin-top: 8px;
      border-radius: 78px;
      padding: 2px 10px;
      font-family: "Inter", Arial, Helvetica, sans-serif;
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      color: #344C38;
    }
  }

  .popular {
    position: absolute;
    font-size: 12px;
    text-transform: uppercase;
    color: #0d2110;
    padding: 8px 16px;
    line-height: 12px;
    margin: 0;
    background: #e1ff32;
    font-weight: bold;
    border-radius: 4px;
    top: -10px;
    right: -10px;
  }

  .selected {
    position: absolute;
    font-size: 12px;
    text-transform: uppercase;
    color: #FFF;
    padding: 8px 16px;
    line-height: 12px;
    margin: 0;
    background: #359c43;
    font-weight: bold;
    border-radius: 4px;
    top: -10px;
    left: -10px;
  }

  .stars {
    margin: 10px 0;
  }

  p {
    font-family: "Rand Mono", Arial, Helvetica, sans-serif;
    font-size: 12px;
    color: #344C38;
    line-height: 16px;
    font-weight: 500;
    text-align: center;
  }

  .font-italic {
    font-style: italic;
    font-family: "Inter", Arial, Helvetica, sans-serif;
  }

  .top {
    text-align: right;
  }


  .support-text {
    color: #FFF;
  }

  .support-text-mobile {    
    color: #6B7280;   
  }

  .support-text,
  .support-text-mobile {
    text-align: right;    
    font-size: 12px;
    font-style: italic;
    line-height: 16px;
    font-weight: 400;    
  }

}

.order-summary-component {
  background: #F5F7ED;
  padding-left: 16px;
  padding-right: 16px;
}

.order-summary-component-mobile {
  background: #FFF;
}

.order-summary-component,
.order-summary-component-mobile {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  

  .title {
    text-align: left;    
    color: #6B7280;    
    font-family: "Inter", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    font-style: italic;
  }

  .content {
    text-align: left;
    font-family: "Inter", Arial, Helvetica, sans-serif;
    font-weight: 700;
    color: #212429;
    margin-bottom: 16px;
  }
}

.order-confirmation-component {
  padding: 10px;
  background: #F5F7ED;
}

.order-confirmation-component-mobile {
  background: #FFF;
}

.order-confirmation-component,
.order-confirmation-component-mobile {
  padding: 10px;

  .youtube {
    margin: 10px;
    max-width: 600px;
    margin: 0 auto;

    iframe {
      max-height: 350px;
      width: 100%;
    }
  }

  .youtube-title {
    font-family: "Inter", Arial, Helvetica, sans-serif;
    color: #666;
    margin-bottom: 16px;

    a {
      text-decoration: none;
    }
  }
}


@media (max-width: 900px) {
  .plan-component {
    .plan-row {
      &.plan-top {
        margin-bottom: 60px;

        .plan-col {
          flex-basis: 50%;
        }
      }

      &.plan-bottom {
        .plan-col {
          flex-basis: 25%;

          &:first-child {
            flex-basis: 50%;
          }
        }
      }
    }

    .pro,
    .pro-plus {
      margin: 0 auto;
    }

    .desktop,
    .desktop-flex {
      display: none !important;
    }

    .mobile {
      display: block !important;
    }

    .mobile-flex {
      display: flex !important;
    }

    .popular,
    .title.mobile {
      position: absolute;
      top: -28px;
      left: 50%;
      transform: translate(-50%, 0);
    }

    .title.mobile {
      font-weight: 900;
      font-size: 24px;
      color: #0d2110;
    }

    .names {
      div {
        font-size: 16px;
      }
    }

    .stats {
      background: #f2f1ed;
      margin-bottom: 64px;
      border-radius: 16px;
      flex-wrap: wrap;
      justify-content: space-between;
      display: flex;
    }

    .image {
      width: 100%;
      height: 240px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
      }
    }

    .humidity span:last-of-type,
    .wind span:last-of-type,
    .feel span:last-of-type {
      font-weight: bold;
    }
  }

  .support-text {
    text-align: left;
    color: #6B7280;
    font-size: 12px;
    font-style: italic;
    line-height: 16px;
    font-weight: 400;
  }
}

.schedule-component {
  .title-section {
    .title {
      font-family: "Inter", Arial, Helvetica, sans-serif;
      font-weight: 600;
      color: #111827;
      padding-bottom: 10px;      
    }
  }

  .autobook-subtitle {
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
    padding-bottom: 16px;
  }

  .am-select-btn-sm {
    border: 2px solid #76A9FA;
    background-color: transparent;
    border-radius: 8px;
    color: #111827;
    font-family: "Inter", Arial, Helvetica, sans-serif;
    font-weight: 400;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    height: 48px;
    padding: 14px 43px 14px 43px;
    width: fit-content;
  }
  .am-select-btn-lg {
    border: 2px solid #76A9FA;
    background-color: white;
    border-radius: 8px;
    color: #111827;
    font-family: "Inter", Arial, Helvetica, sans-serif;
    font-weight: 400;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    height: 75px;
    width: 120px;
    padding: 14px 43px 14px 43px;
    width: fit-content;
  }
  .pm-select-btn-sm {
    border: 2px solid #FACA15;
    background-color: transparent;
    border-radius: 8px;
    color: #111827;
    font-family: "Inter", Arial, Helvetica, sans-serif;
    font-weight: 400;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    height: 48px;
    padding: 14px 43px 14px 43px;
    width: fit-content;
  }
  .pm-select-btn-lg {
    border: 2px solid #FACA15;
    background-color: white;
    border-radius: 8px;
    color: #111827;
    font-family: "Inter", Arial, Helvetica, sans-serif;
    font-weight: 400;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    height: 75px;
    width: 120px;
    padding: 14px 43px 14px 43px;
    width: fit-content;
  }
  .not-avail {
    font-family: "Inter", Arial, Helvetica, sans-serif;
    font-weight: 400;
    text-align: center;
    line-height: 16px;
    border: 1px dashed #333333;
    padding: 14px 20px 14px 20px;
  }
  .date-slot-title {
    font-family: "Inter", Arial, Helvetica, sans-serif;
    font-weight: 700;
    text-align: right;
    font-size: 13px;
    line-height: 16px;
    color: #111827;
    margin-bottom: 0px;
  }
  .am-select-btn-sm.selected-date-slot {
    background-color: #76A9FA;
    font-weight: 700;
  }
  .am-select-btn-lg.selected-date-slot {
    background-color: #76A9FA;
    font-weight: 700;
  }
  .pm-select-btn-sm.selected-date-slot {
    background-color: #FACA15;
    font-weight: 700;
  }
  .pm-select-btn-lg.selected-date-slot {
    background-color: #FACA15;
    font-weight: 700;
  }
  .date-range {
    font-family: "Inter", Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #374151;
  }
  .arrow-icon {
    padding: 6px;
  }
  .selected-date {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    font-family: "Rand", Arial, Helvetica, sans-serif;
    font-weight: 400;
  }
  .confirm-btn{
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    font-family: "Rand", Arial, Helvetica, sans-serif;
    font-weight: 700;
  }
}
.bg-desktop {
  background: #F5F7ED;
}